import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getSession, setSession } from "../../config/session";
import api from "../../config/api";
import {
  addSupplier,
  updateSupplier,
  deleteReconciliations,
  getReconciliations,
  exportReconciliationList,
} from "../../layouts/Admin/actions/InventoryActions";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import queryString from "query-string";
import Pagination from "react-js-pagination";
import { PulseLoader } from "react-spinners";
import NotificationAlert from "react-notification-alert";
import LoadingOverlay from "react-loading-overlay";
import { format, startOfDay, endOfDay } from "date-fns";
import DatePicker from "react-datepicker";

import {
  Alert,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Row,
  Col,
  FormGroup,
  Input,
  Badge,
} from "reactstrap";

class Reconcilation extends React.Component {
  constructor(props) {
    const defaultPage = JSON.parse(getSession("defaultPage"));
    const userData = JSON.parse(getSession("userData"));
    const datesStr = new Date().toISOString();
    super(props);
    this.state = {
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
      startDateStr: datesStr,
      endDateStr: datesStr,
      reconciliations: [],
      checked: [],
      defaultPage: "",
      pagination: {},
      activePage: 1,
      selectedPage: "",
      pageName: "",
      sortBy: "",
      sort: "",
      keyword: "",
      status: "",
      stockStatus: "",
      kind: "",
      size: 25,
      inventoryProducts: [],
      selectedSupplier: null,
      placeId: defaultPage,
      user: userData && userData.userId ? userData.userId : "",
      isFiccoManager:
        userData && userData.isFiccoManager ? userData.isFiccoManager : false,
      isLoading: true,
      isProcessing: false,
      isGrocery: false,
      hideTutorials: false,
      addSupplierModalError: "",
      submittedModal: false,
      modal: false,
      modalView: false,
    };
  }

  componentDidMount() {
    const userData = JSON.parse(getSession("userData"));
    let pageInfo = JSON.parse(getSession("pageInfo"));
    if (pageInfo && pageInfo.pageType === "5cd141d10d437be772373ddb") {
      this.setState({ isGrocery: true });
    }

    if (userData !== null) {
      let defaultPage = JSON.parse(getSession("defaultPage"));
      if (defaultPage) {
        console.log(userData);
        if (defaultPage !== "") {
          let url = this.props.location.search;
          let query = queryString.parse(url);
          let activePage = query.page ? Number(query.page) : 1;
          let status = query.status ? query.status : "";
          let sortBy = query.sortBy ? query.sortBy : "created";
          let sort = query.sort ? query.sort : "desc";
          let keyword = query.keyword ? query.keyword : "";
          let size = query.size ? query.size : 25;
          let kind = query.kind ? query.kind : "";
          let stockStatus = query.stockStatus ? query.stockStatus : "";
          if (query.category) {
          } else {
            delete query.subCategory;
          }
          let queryStr = "?" + queryString.stringify(query);
          this.setState({
            activePage: activePage,
            selectedPage: defaultPage,
            status: status,
            sort: sort,
            sortBy: sortBy,
            keyword: keyword,
            kind,
            stockStatus,
            size,
            pageName: pageInfo && pageInfo.name ? pageInfo.name : "",
            hideTutorials:
              pageInfo &&
              pageInfo.hideTutorials &&
              pageInfo.hideTutorials === true
                ? true
                : false,
          });
          this.refreshList(queryStr, defaultPage);
        }
      } else {
      }
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
    this._isMount = true;
  }

  componentWillUnmount() {
    this._isMount = false;
  }

  renderRows(reconciliation) {
    if (this.state.isLoading) {
      return (
        <tr>
          <td colSpan={7}>
            <PulseLoader
              sizeUnit={"px"}
              size={15}
              color={"#1d8cf8"}
              loading={this.state.isLoading}
            />
          </td>
        </tr>
      );
    } else {
      if (reconciliation instanceof Array && reconciliation.length > 0) {
        return reconciliation.map((item, index) => {
          const totalVarianceByUnit = item.products.reduce((acc, product) => {
            const volumeType = product.unit || "";
            const varianceValue = product.difference || 0;

            if (!acc[volumeType]) {
              acc[volumeType] = 0;
            }
            acc[volumeType] += varianceValue;
            return acc;
          }, {});

          return (
            <tr key={index}>
              <td>
                <Link
                  to={
                    "/inventory-reconciliation/" +
                    item.id +
                    "/edit" +
                    (this.state.activePage > 0 ||
                    this.state.keyword !== "" ||
                    this.state.status !== ""
                      ? "?"
                      : "") +
                    (this.state.activePage > 0
                      ? "&page=" + this.state.activePage
                      : "") +
                    (this.state.keyword !== ""
                      ? "&keyword=" + this.state.keyword
                      : "") +
                    (this.state.status !== ""
                      ? "&status=" + this.state.status
                      : "")
                  }
                  style={{ padding: "0 0.1rem" }}
                  className="text-medium"
                  alt={item.name}
                  title={item.name}
                >
                  {format(new Date(item.createdAt), "MMM dd, yyyy")}
                </Link>
              </td>
              <td className="text-medium">{item.description}</td>
              <td>
                {Object.entries(totalVarianceByUnit).map(
                  ([volumeType, totalVariance]) => (
                    <span key={volumeType} align="center">
                      <Badge color="primary" pill>
                        {totalVariance} {volumeType}
                      </Badge>{" "}
                    </span>
                  )
                )}
              </td>
              <td className="text-medium">
                {item.preparedBy.firstName} {item.preparedBy.lastName}
              </td>
              {item.status === "unpublished" ? (
                <td className="text-medium">{""}</td>
              ) : (
                <td className="text-medium">
                  {" "}
                  {item.publishedBy.firstName} {item.publishedBy.lastName}
                </td>
              )}
              {item.status === "unpublished" && (
                <td>
                  <Badge style={{ backgroundColor: "#D5CB3C" }} pill>
                    For Publish
                  </Badge>
                </td>
              )}
              {item.status === "published" && (
                <td>
                  <Badge color="success" pill>
                    Published
                  </Badge>
                </td>
              )}
              {item.status === "declined" && (
                <td>
                  <Badge color="danger" pill>
                    Declined
                  </Badge>
                </td>
              )}

              <td align="center">
                {item.status !== "published" && (
                  <Link
                    to={
                      "/inventory-reconciliation/" +
                      item.id +
                      "/edit" +
                      (this.state.activePage > 0 ||
                      this.state.keyword !== "" ||
                      this.state.status !== ""
                        ? "?"
                        : "") +
                      (this.state.activePage > 0
                        ? "&page=" + this.state.activePage
                        : "") +
                      (this.state.keyword !== ""
                        ? "&keyword=" + this.state.keyword
                        : "") +
                      (this.state.status !== ""
                        ? "&status=" + this.state.status
                        : "")
                    }
                    style={{ padding: "0 0.1rem" }}
                    title="View"
                  >
                    <Fa icon="edit" />
                  </Link>
                )}
                {item.status !== "published" &&
                this.state.isFiccoManager === false ? (
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={this.handleDelete}
                    data-item={item.id}
                  >
                    <Fa icon="trash-alt" />
                  </Link>
                ) : (
                  ""
                )}
                &nbsp;
                <Link to="#" className="text-light" data-item={item.id}>
                  <Fa icon="print" />
                </Link>
              </td>
              <td></td>
              {/* <td>&nbsp;</td> */}
            </tr>
          );
        });
      } else {
        return (
          <tr>
            <td colSpan={7}>
              <h5 className="text-danger">
                <em>No item(s) found.</em>
              </h5>
            </td>
          </tr>
        );
      }
    }
  }

  renderCategories(categories) {
    if (categories instanceof Array && categories.length > 0) {
      if (categories.length === 1) {
        return categories.map((cat) => cat.name);
      } else {
        return categories.map((cat) => cat.name + ", ");
      }
    }
  }

  onDismiss = () => {
    this.setState({ hideTutorials: true });
    let pageInfo = JSON.parse(getSession("pageInfo"));
    pageInfo.hideTutorials = true;
    setSession("pageInfo", JSON.stringify(pageInfo));
  };

  onHideTutorials = () => {
    if (
      !window.confirm("Are you sure you want to hide this section permanently?")
    ) {
      return false;
    }
    let pageInfo = JSON.parse(getSession("pageInfo"));
    let userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    const place = {
      hideTutorials: true,
    };
    api(sessionToken)
      .patch(`/provider/places/${pageInfo._id}`, place)
      .then((response) => {
        if (response && response.data && response.data.status === "ok") {
          this.setState({ hideTutorials: true });
          pageInfo.hideTutorials = true;
          setSession("pageInfo", JSON.stringify(pageInfo));
        } else {
          this.showNotificationError(
            "An unknown error occured. Please try again!"
          );
        }
      })
      .catch((error) => {
        this.setState({ submitted: false, isSaving: false });
        if (error.response && typeof error.response === "string") {
          this.showNotificationError(error.response);
        } else {
          this.showNotificationError(
            "An unknown error occured. Please try again!"
          );
        }
      });
  };

  showNotification(message) {
    if (message && typeof message === "string") {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "success",
        icon: "",
        autoDismiss: 2,
      };
      if (
        notification &&
        this.refs.notify &&
        this.refs.notify.notificationAlert
      ) {
        this.refs.notify.notificationAlert(notification);
      }
    }
  }

  showNotificationError(message) {
    if (message && typeof message === "string") {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "danger",
        icon: "",
        autoDismiss: 2,
      };
      if (
        notification &&
        this.refs.notify &&
        this.refs.notify.notificationAlert
      ) {
        this.refs.notify.notificationAlert(notification);
      }
    }
  }

  handleChangeKeyword = (e) => {
    let { value } = e.target;
    this.setState({ keyword: value });
  };

  handleEnter = (e) => {
    let { key } = e;
    if (key === "Enter") {
      this.setState({ isLoading: true, activePage: 1 });
      let { keyword } = this.state;

      let defaultPage = JSON.parse(getSession("defaultPage"));
      let url = this.props.location.search;
      let query = queryString.parse(url);
      delete query.message;
      delete query.page;
      if (keyword !== "") {
        query.keyword = keyword.trim();
      } else {
        delete query.keyword;
      }
      let queryStr = "?" + queryString.stringify(query);
      this.refreshList(queryStr, defaultPage);
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ isLoading: true });
    let defaultPage = JSON.parse(getSession("defaultPage"));

    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    query.page = pageNumber;
    let queryStr = "?" + queryString.stringify(query);

    this.setState({ activePage: pageNumber });
    this.refreshList(queryStr, defaultPage);
  };

  handleChangePerPage = (e) => {
    this.setState({ isLoading: true });
    let { value } = e.target;

    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    delete query.page;
    if (value !== "") {
      query.size = value;
    } else {
      delete query.size;
    }
    let queryStr = "?" + queryString.stringify(query);
    this.setState({ size: value, activePage: 1 });
    this.refreshList(queryStr, defaultPage);
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      supplier: {
        ...this.state.supplier,
        [name]: value,
      },
    });
  };

  handleSubmitSupplier = (e) => {
    e.preventDefault();
    const userData = JSON.parse(getSession("userData"));

    if (userData) {
      const sessionToken = userData.sessionToken;
      const supplier = { ...this.state.supplier };
      let hasError = false;
      if (supplier.name) {
        this.setState({ submitted: true });
        if (!hasError) {
          if (!window.confirm("Do you want to save this item?")) {
            return false;
          }
          this.setState({ isSaving: true });

          this.props.addSupplier(supplier, sessionToken, (err, res) => {
            if (res) {
              this.setState({ submitted: false, isSaving: false });
              if (res.id) {
                this.showNotification("Supplier has been added");
                this.toggleModal();

                let defaultPage = JSON.parse(getSession("defaultPage"));
                let url = this.props.location.search;
                let query = queryString.parse(url);
                delete query.message;
                delete query.page;
                let queryStr = "?" + queryString.stringify(query);
                this.refreshList(queryStr, defaultPage);
              }
            } else {
              if (err) {
                this.setState({ submitted: false, isSaving: false });
                const { response } = err;
                let msg = "";
                if (typeof response === "string") msg = response;
                else {
                  if (
                    response.data !== null &&
                    response.data.message !== null &&
                    typeof response.data.message === "string"
                  ) {
                    msg = response.data.message;
                  }
                }
                this.setState({ addSupplierModalError: msg });
              } else {
                this.setState({
                  submitted: false,
                  isSaving: false,
                  addSupplierModalError:
                    "An unknown error occured. Please try again.",
                });
              }
            }
          });
        }
      } else {
        this.setState({ submitted: true });
        this.showNotificationError(
          "Some fields are required. Please fill in the required fields"
        );
      }
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
  };
  handleUpdateSupplier = (e) => {
    e.preventDefault();
    const userData = JSON.parse(getSession("userData"));
    if (userData) {
      const sessionToken = userData.sessionToken;
      const { supplier } = this.state;
      let hasError = false;
      if (supplier.name) {
        this.setState({ submitted: true });

        if (!hasError) {
          if (!window.confirm("Do you want to update this item?")) {
            return false;
          }

          this.setState({ isSaving: true });

          this.props.updateSupplier(
            supplier.id,
            supplier,
            sessionToken,
            (err, res) => {
              if (res) {
                this.setState({ submitted: false, isSaving: false });
                let msg = "Supplier changes has been saved.";
                if (res && res.status === "success") {
                  if (res.message && typeof res.message === "string") {
                    msg = res.message;
                  }
                  this.showNotification(msg);
                  this.toggleModal();

                  let defaultPage = JSON.parse(getSession("defaultPage"));
                  let url = this.props.location.search;
                  let query = queryString.parse(url);
                  delete query.message;
                  delete query.page;
                  let queryStr = "?" + queryString.stringify(query);
                  this.refreshList(queryStr, defaultPage);
                }
              } else {
                if (err) {
                  console.error({ err });
                  this.setState({ submitted: false, isSaving: false });
                  const { response } = err;
                  let msg = "";
                  if (typeof response === "string") msg = response;
                  else {
                    if (
                      response.data !== null &&
                      response.data.message !== null &&
                      typeof response.data.message === "string"
                    ) {
                      msg = response.data.message;
                    }
                  }
                  this.showNotificationError(msg);
                } else {
                  this.setState({ submitted: false, isSaving: false });
                  this.showNotificationError(
                    "An unknown error occured. Please try again."
                  );
                }
              }
            }
          );
        }
      } else {
        this.showNotificationError(
          "Some fields are required. Please fill in the required fields"
        );
      }
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
  };

  handleDelete = (e) => {
    if (!window.confirm("Do you want to delete this reconciliation?")) {
      return false;
    }
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    const message = "Reconciliation has been deleted successfully.";
    let queryStr = "?" + queryString.stringify(query);
    const productId = e.currentTarget.dataset.item;
    if (productId) {
      const userData = JSON.parse(getSession("userData"));
      if (userData) {
        const sessionToken = userData.sessionToken;
        this.props.deleteReconciliations(
          productId,
          sessionToken,
          (error, result) => {
            if (!error && result) {
              if (result.status === 204) {
                this.showNotification(message);
                this.refreshList(queryStr, defaultPage);
              }
            } else {
              if (error) {
                if (
                  error.response &&
                  error.response.status &&
                  error.response.status === 403
                ) {
                  setTimeout(() => {
                    this.setState({ isLoading: true });
                    let defaultPage = JSON.parse(getSession("defaultPage"));
                    let url = this.props.location.search;
                    let query = queryString.parse(url);
                    delete query.message;
                    delete query.page;
                    let queryStr = "?" + queryString.stringify(query);
                    this.setState({ activePage: 1 });
                    this.refreshList(queryStr, defaultPage);
                  }, 1000);
                  this.showNotificationError(
                    "You are not allowed to delete this reconciliation."
                  );
                } else {
                  this.showNotificationError(error.response);
                }
              }
            }
          }
        );
      } else {
        this.props.history.push("/login");
        window.location.reload();
      }
    }
  };
  handleChangeProductStatus = (e) => {
    this.setState({ isLoading: true });
    let { value } = e.target;

    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    delete query.page;
    if (value !== "") {
      query.status = value;
    } else {
      delete query.status;
    }
    let queryStr = "?" + queryString.stringify(query);

    this.setState({ status: value, activePage: 1 });
    this.refreshList(queryStr, defaultPage);
  };
  refreshList(queryStr, pageId) {
    const query = queryString.parse(queryStr);
    const message = query.message;
    const checkAll = document.getElementById("check-all");
    if (checkAll) {
      checkAll.checked = false;
    }
    const checkboxes = document.querySelectorAll('input[name="item"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    if (message) {
      delete query.message;
      this.showNotification(message);
    }

    this.props.history.push("/inventory-reconciliation" + queryStr);
    const userData = JSON.parse(getSession("userData"));
    const pageInfo = JSON.parse(getSession("pageInfo"));
    const { sessionToken } = userData;
    if (userData !== null && pageInfo) {
      this.props.getReconciliations(pageId, query, sessionToken, (err, res) => {
        if (!err && res) {
          this.setState({
            reconciliations: res.docs,
            pagination: {
              limit: res.pageSize,
              total: res.totalRows,
            },
          });
        }
        this.setState({ isLoading: false });
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  exportfileList(e) {
    e.preventDefault();
    const userData = JSON.parse(getSession("userData"));
    const pageInfo = JSON.parse(getSession("pageInfo"));
    let defaultPage = JSON.parse(getSession("defaultPage"));
    const startDate = this.state.startDateStr;
    const endDate = this.state.endDateStr;
    const status = this.state.status;

    const { sessionToken } = userData;
    if (userData !== null && pageInfo) {
      let queryStr = `?place=${defaultPage}&dateStart=${startDate}&dateEnd=${endDate}&status=${status}`;
      const query = queryString.parse(queryStr);
      this.props.exportReconciliationList(query, sessionToken, (err, res) => {
        if (!err && res) {
          queryString.parse(
            `https://view.officeapps.live.com/op/view.aspx?src=${queryStr}&wdOrigin=BROWSELINK`
          );
        }
        this.setState({ isLoading: false });
      });
    } else {
      this.setState({ isLoading: false });
    }
  }
  handleChangeStartDate = (date) => {
    const formattedDateStart = format(date, "yyyy-MM-dd");
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    delete query.page;
    if (date !== "") {
      query.dateStart = formattedDateStart;
    } else {
      delete query.dateStart;
    }

    this.setState({ startDate: date });
    this.setState({ startDateStr: formattedDateStart });
    let queryStr = "?" + queryString.stringify(query);
    this.refreshList(queryStr, defaultPage);
  };

  handleChangeEndDate = (date) => {
    const formattedDateEnd = format(date, "yyyy-MM-dd");
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    delete query.page;
    if (date !== "") {
      query.dateEnd = formattedDateEnd;
    } else {
      delete query.dateEnd;
    }
    this.setState({ endDate: date });
    this.setState({ endDateStr: formattedDateEnd });
    let queryStr = "?" + queryString.stringify(query);
    this.refreshList(queryStr, defaultPage);
  };
  renderNoPageAdded() {
    return (
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref="notify" />
        </div>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Alert color="danger">
                  <h4 className="alert-heading">No Page Added</h4>
                  <hr />
                  <p className="mb-0">
                    You have not added a page yet. Click{" "}
                    <Link to="/add-page">here</Link> to add a new page.
                  </p>
                </Alert>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const pageTitle = "Reconciliation";
    let pageInfo = JSON.parse(getSession("pageInfo"));
    if (pageInfo) {
      if (pageInfo.pageType === "5dea2304f6bba08323a3ddce") {
        this.props.history.push("/tours");
        window.location.reload();
      }
    }
    if (pageInfo && pageInfo._id) {
      if (pageInfo.isVerified === true) {
        return (
          <>
            <div className="content">
              <div className="react-notification-alert-container">
                <NotificationAlert ref="notify" />
              </div>
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <h4 className="title">
                        {pageTitle} - <em>{pageInfo.name}</em>
                      </h4>

                      <Row>
                        <Col className="pr-md-1" md="3">
                          <FormGroup>
                            <label htmlFor="keyword" className="control-label">
                              Search
                            </label>
                            <Input
                              id="keyword"
                              name="keyword"
                              type="text"
                              placeholder="Search item..."
                              onChange={this.handleChangeKeyword}
                              onKeyPress={this.handleEnter}
                              value={this.state.keyword}
                            ></Input>
                          </FormGroup>
                        </Col>
                        <Col className="pr-md-1" md="3">
                          <FormGroup>
                            <label htmlFor="status" className="control-label">
                              Status
                            </label>
                            <Input
                              id="status"
                              name="status"
                              type="select"
                              onChange={this.handleChangeProductStatus}
                              value={this.state.status}
                            >
                              <option value="">Status</option>
                              <option value="unpublished">For Publish</option>
                              <option value="published">Published</option>
                              <option value="declined">Declined</option>
                            </Input>
                          </FormGroup>
                        </Col>

                        <Col className="pr-md-1" md="3">
                          <FormGroup>
                            <label
                              htmlFor="startDate"
                              className="control-label"
                              style={{ display: "block" }}
                            >
                              Start Date:
                            </label>

                            <DatePicker
                              name="startDate"
                              className="form-control"
                              selectsStart
                              startDate={this.state.startDate}
                              endDate={this.state.endDate}
                              selected={this.state.startDate}
                              onChange={this.handleChangeStartDate}
                              dateFormat="MMMM d, yyyy"
                              autoComplete="off"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-md-1" md="3">
                          <FormGroup>
                            <label
                              htmlFor="endDate"
                              className="control-label"
                              style={{ display: "block" }}
                            >
                              End Date:
                            </label>

                            <DatePicker
                              name="endDate"
                              selectsEnd
                              startDate={this.state.startDate}
                              endDate={this.state.endDate}
                              className="form-control"
                              selected={this.state.endDate}
                              onChange={this.handleChangeEndDate}
                              dateFormat="MMMM d, yyyy"
                              autoComplete="off"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row className="pull-right">
                        <Col md="12">
                          <Link
                            to="#"
                            onClick={this.exportfileList.bind(this)}
                            className="btn btn-round btn-info btn-sm"
                          >
                            <Fa icon="print" /> &nbsp;Print Report
                          </Link>
                          {this.state.isFiccoManager === false ? (
                            <Link
                              to="/inventory-reconciliation/new"
                              className="btn btn-round btn-info btn-sm"
                            >
                              <Fa icon="plus" />
                              &nbsp;Add New
                            </Link>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Table className="tablesorter table-striped" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>Date Created</th>
                            <th style={{ whiteSpace: "nowrap" }}>
                              Description
                            </th>
                            <th style={{ whiteSpace: "nowrap" }}>
                              Total Variance
                            </th>
                            <th style={{ whiteSpace: "nowrap" }}>
                              Prepared By
                            </th>
                            <th style={{ whiteSpace: "nowrap" }}>
                              Published by
                            </th>
                            <th style={{ whiteSpace: "nowrap" }}>Status</th>
                            <th style={{ whiteSpace: "nowrap" }}>Action</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.renderRows(this.state.reconciliations)}
                        </tbody>
                      </Table>
                    </CardBody>
                    <CardFooter>
                      <Row>
                        <Col md="12">
                          <Row>
                            <Col md="12" lg="12">
                              {this.state.pagination &&
                                this.state.pagination.total > 0 && (
                                  <>
                                    <Pagination
                                      innerClass="pagination"
                                      activePage={this.state.activePage}
                                      itemsCountPerPage={
                                        this.state.pagination.limit
                                      }
                                      totalItemsCount={
                                        this.state.pagination.total
                                      }
                                      pageRangeDisplayed={5}
                                      onChange={this.handlePageChange}
                                    />
                                    <p>
                                      Page <em>{this.state.activePage}</em> of{" "}
                                      <em>
                                        {Math.ceil(
                                          this.state.pagination.total /
                                            this.state.pagination.limit
                                        )}
                                      </em>{" "}
                                      of{" "}
                                      <em>
                                        {numberWithCommasOnly(
                                          this.state.pagination.total
                                        )}
                                      </em>{" "}
                                      items.
                                    </p>
                                    <Input
                                      style={{
                                        marginBottom: "5px",
                                        width: "auto",
                                      }}
                                      id="perPage"
                                      name="perPage"
                                      type="select"
                                      onChange={this.handleChangePerPage}
                                      value={this.state.size}
                                    >
                                      <option value="10">10 / page</option>
                                      <option value="25">25 / page</option>
                                      <option value="50">50 / page</option>
                                      <option value="100">100 / page</option>
                                    </Input>
                                  </>
                                )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </div>
            <LoadingOverlay
              active={this.state.isProcessing}
              spinner
              text="Processing..."
            ></LoadingOverlay>
            <LoadingOverlay
              active={this.state.isGeneratingXls}
              spinner
              text="Processing..."
            ></LoadingOverlay>
          </>
        );
      } else {
        return this.renderPageNotVerified();
      }
    } else {
      return this.renderNoPageAdded();
    }
  }
}

const numberWithCommasOnly = (x) => {
  return priceRound(x, 0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const priceRound = (price, dec) => {
  if (dec === undefined) {
    dec = 2;
  }
  if (price !== 0) {
    if (!price || isNaN(price)) {
      throw new Error("price is not a number" + price);
    }
  }
  const str = parseFloat(Math.round(price * 100) / 100).toFixed(dec);
  return str;
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  deleteReconciliations,
  addSupplier,
  updateSupplier,
  getReconciliations,
  exportReconciliationList,
})(Reconcilation);
