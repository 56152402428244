import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getSession, setSession } from "../../config/session";
import api from "../../config/api";
import {
  getProductsByPageId,
  getProductsGroceryByPageId,
  getProductsByProviderId,
  updateProduct,
  deleteRestored,
  getRestoredList,
  exportRestoredList,
} from "../../layouts/Admin/actions/ProductActions";
import {
  getProductCategoriesAll,
  getProductCategoriesAllV2,
} from "../../layouts/Admin/actions/ProductCategoryActions";
import { getProviderPlaces } from "../../layouts/User/UserActions";
import queryString from "query-string";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import Pagination from "react-js-pagination";
import { PulseLoader } from "react-spinners";
import NotificationAlert from "react-notification-alert";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import { format, startOfDay, endOfDay } from "date-fns";
import DatePicker from "react-datepicker";
import defaultProductImage from "../../assets/img/product-image-default.png";

import {
  Alert,
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Row,
  Col,
  Media,
  FormGroup,
  Input,
} from "reactstrap";

class RestoredProducts extends React.Component {
  constructor(props) {
    const datesStr = new Date().toISOString().split("T")[0];
    super(props);
    this.state = {
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
      startDateStr: datesStr,
      endDateStr: datesStr,
      restoredList: [],
      products: [],
      places: [],
      productTypes: [],
      productCategoriesAll: [],
      productCategories: [],
      productCategoriesSub: [],
      checked: [],
      defaultPage: "",
      pagination: {},
      activePage: 1,
      selectedPage: "",
      selectedAvailability: "",
      selectedProductCategory: "",
      selectedProductCategorySub: "",
      pageName: "",
      sortBy: "",
      sort: "",
      status: "",
      keyword: "",
      size: 25,
      isLoading: true,
      isProcessing: false,
      isGeneratingXls: false,
      isGrocery: false,
      hideTutorials: false,
    };
  }

  componentDidMount() {
    const userData = JSON.parse(getSession("userData"));
    let pageInfo = JSON.parse(getSession("pageInfo"));
    if (pageInfo && pageInfo.pageType === "5cd141d10d437be772373ddb") {
      this.setState({ isGrocery: true });
    }

    if (userData !== null) {
      let defaultPage = JSON.parse(getSession("defaultPage"));
      const sessionToken = userData.sessionToken;

      if (defaultPage) {
        if (defaultPage !== "") {
          let url = this.props.location.search;
          let query = queryString.parse(url);
          let activePage = query.page ? Number(query.page) : 1;
          let status = query.status ? query.status : "";
          let sortBy = query.sortBy ? query.sortBy : "created";
          let sort = query.sort ? query.sort : "desc";
          let keyword = query.keyword ? query.keyword : "";
          let size = query.size ? query.size : 25;
          let category = query.category ? query.category : "";
          let subCategory = "";
          if (query.category) {
            subCategory = query.subCategory ? query.subCategory : "";
          } else {
            delete query.subCategory;
          }
          let queryStr = "?" + queryString.stringify(query);
          this.setState({
            activePage: activePage,
            selectedPage: defaultPage,
            status: status,
            sort: sort,
            sortBy: sortBy,
            keyword: keyword,
            size: size,
            selectedProductCategory: category,
            selectedProductCategorySub: subCategory,
            pageName: pageInfo && pageInfo.name ? pageInfo.name : "",
            hideTutorials:
              pageInfo &&
              pageInfo.hideTutorials &&
              pageInfo.hideTutorials === true
                ? true
                : false,
          });
          if (pageInfo && pageInfo.pageType === "5cd141d10d437be772373ddb") {
            this.setState({ isGrocery: true });
          }
          this.refreshRestoredProductList(queryStr, defaultPage);
          this.getCategories(pageInfo, defaultPage, sessionToken);
        } else {
          this.props.getProviderPlaces(sessionToken, (error, result) => {
            if (!error && result) {
              if (
                result.places &&
                result.places instanceof Array &&
                result.places.length > 0
              ) {
                const defaultPlace = result.places.find(
                  (item) => item.isDefault
                );
                if (defaultPlace && defaultPlace._id) {
                  defaultPage = defaultPlace._id;
                  pageInfo = defaultPlace;
                } else {
                  defaultPage = result.places[0]._id;
                  pageInfo = result.places[0];
                }
              }
              setSession("pageInfo", JSON.stringify(pageInfo));
              let url = this.props.location.search;
              let query = queryString.parse(url);
              let activePage = query.page ? Number(query.page) : 1;
              let status = query.status ? query.status : "";
              let sortBy = query.sortBy ? query.sortBy : "created";
              let sort = query.sort ? query.sort : "desc";
              let keyword = query.keyword ? query.keyword : "";
              let size = query.size ? query.size : 25;
              let category = query.category ? query.category : "";
              let subCategory = "";
              if (query.category) {
                subCategory = query.subCategory ? query.subCategory : "";
              } else {
                delete query.subCategory;
              }
              let queryStr = "?" + queryString.stringify(query);
              this.setState({
                activePage: activePage,
                selectedPage: defaultPage,
                status: status,
                sort: sort,
                sortBy: sortBy,
                keyword: keyword,
                size: size,
                selectedProductCategory: category,
                selectedProductCategorySub: subCategory,
                pageName: pageInfo && pageInfo.name ? pageInfo.name : "",
              });
              if (
                pageInfo &&
                pageInfo.pageType === "5cd141d10d437be772373ddb"
              ) {
                this.setState({ isGrocery: true });
              }
              this.refreshRestoredProductList(queryStr, defaultPage);
              this.getCategories(pageInfo, defaultPage, sessionToken);
            } else {
              this.setState({ isLoading: false });
            }
          });
        }
      } else {
        this.props.getProviderPlaces(sessionToken, (error, result) => {
          if (!error && result) {
            if (
              result.places &&
              result.places instanceof Array &&
              result.places.length > 0
            ) {
              const defaultPlace = result.places.find((item) => item.isDefault);
              if (defaultPlace && defaultPlace._id) {
                defaultPage = defaultPlace._id;
                pageInfo = defaultPlace;
              } else {
                defaultPage = result.places[0]._id;
                pageInfo = result.places[0];
              }
              setSession("pageInfo", JSON.stringify(pageInfo));
              let url = this.props.location.search;
              let query = queryString.parse(url);
              let activePage = query.page ? Number(query.page) : 1;
              let status = query.status ? query.status : "";
              let sortBy = query.sortBy ? query.sortBy : "created";
              let sort = query.sort ? query.sort : "desc";
              let keyword = query.keyword ? query.keyword : "";
              let size = query.size ? query.size : 25;
              let category = query.category ? query.category : "";
              let subCategory = "";
              if (query.category) {
                subCategory = query.subCategory ? query.subCategory : "";
              } else {
                delete query.subCategory;
              }
              let queryStr = "?" + queryString.stringify(query);
              this.setState({
                activePage: activePage,
                selectedPage: defaultPage,
                status: status,
                sort: sort,
                sortBy: sortBy,
                keyword: keyword,
                size: size,
                selectedProductCategory: category,
                selectedProductCategorySub: subCategory,
                pageName: pageInfo.name,
              });
              if (
                pageInfo &&
                pageInfo.pageType === "5cd141d10d437be772373ddb"
              ) {
                this.setState({ isGrocery: true });
              }
              this.refreshRestoredProductList(queryStr, defaultPage);
              this.getCategories(pageInfo, defaultPage, sessionToken);
            } else {
              this.setState({ isLoading: false });
            }
          } else {
            this.setState({ isLoading: false });
          }
        });
      }
    } else {
      this.props.history.push("/login");
      window.location.reload();
    }
    this._isMount = true;
  }

  exportfileList(e) {
    e.preventDefault();
    const userData = JSON.parse(getSession("userData"));
    const pageInfo = JSON.parse(getSession("pageInfo"));
    let defaultPage = JSON.parse(getSession("defaultPage"));
    const startDate = this.state.startDateStr;
    const endDate = this.state.endDateStr;
    const { sessionToken } = userData;
    if (userData !== null && pageInfo) {
      let queryStr = `?place=${defaultPage}&dateStart=${startDate}&dateEnd=${endDate}`;
      const query = queryString.parse(queryStr);
      this.props.exportRestoredList(query, sessionToken, (err, res) => {
        if (!err && res) {
        }
        this.setState({ isLoading: false });
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  handleChangeStartDate = (date) => {
    const formattedDateStart = format(date, "yyyy-MM-dd");
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    delete query.page;
    if (date !== "") {
      query.dateStart = formattedDateStart;
    } else {
      delete query.dateStart;
    }

    this.setState({ startDate: date });
    this.setState({ startDateStr: formattedDateStart });
    let queryStr = "?" + queryString.stringify(query);
    this.refreshRestoredProductList(queryStr, defaultPage);
  };

  handleChangeEndDate = (date) => {
    const formattedDateEnd = format(date, "yyyy-MM-dd");
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    delete query.page;
    if (date !== "") {
      query.dateEnd = formattedDateEnd;
    } else {
      delete query.dateEnd;
    }
    this.setState({ endDate: date });
    this.setState({ endDateStr: formattedDateEnd });
    let queryStr = "?" + queryString.stringify(query);
    this.refreshRestoredProductList(queryStr, defaultPage);
  };
  componentWillUnmount() {
    this._isMount = false;
  }
  renderProducts(products) {
    if (products instanceof Array && products.length > 0) {
      if (products.length === 1) {
        return products.map(function (e) {
          return (
            <Badge color="primary" pill>
              {e.name} {e.unit}
            </Badge>
          );
        });
      } else {
        return products.map(function (e) {
          return (
            <Badge color="primary" pill>
              {e.name} {e.unit}
            </Badge>
          );
        });
      }
    }
  }
  renderRows(restoredList) {
    let pageInfo = JSON.parse(getSession("pageInfo"));

    if (this.state.isLoading) {
      return (
        <tr>
          <td colSpan={8}>
            <PulseLoader
              sizeUnit={"px"}
              size={15}
              color={"#1d8cf8"}
              loading={this.state.isLoading}
            />
          </td>
        </tr>
      );
    } else {
      if (restoredList instanceof Array && restoredList.length > 0) {
        return restoredList.map((item, index, productsArr) => (
          <tr key={index}>
            <td width="150">
              {format(new Date(item.createdAt), "MMM/dd/yyyy hh:mm a")}
            </td>{" "}
            <td>
              {
                <Badge color="primary" pill>
                  {item.product && item.product.name}
                </Badge>
              }
            </td>
            <td> {item.product && item.product.unit} </td>
            {pageInfo && pageInfo.useCreatedProductCategory ? (
              <td width="150">
                {this.renderCategories(item.productCategories)}
              </td>
            ) : (
              <td width="150">{this.renderCategories(item.categories)}</td>
            )}
            <td align="left">
              &#8369;
              {item.product.price
                ? numberWithCommas(item.product.price)
                : "0.00"}
            </td>
            <td width="150">
              {format(new Date(item.expirationDate), "MMM/dd/yyyy hh:mm a")}
            </td>
            <td>
              {item.restored === true ? (
                <Badge color="success" pill>
                  Available
                </Badge>
              ) : (
                <Badge color="danger" pill>
                  Unavailable
                </Badge>
              )}
            </td>
            <td align="center">
              <Link
                to={
                  "/product-restored/" +
                  item.id +
                  "/edit" +
                  (this.state.activePage > 0 ||
                  this.state.keyword !== "" ||
                  this.state.status !== "" ||
                  this.state.keyword !== "" ||
                  this.state.selectedAvailability !== "" ||
                  this.state.selectedProductCategory !== "" ||
                  this.state.selectedProductCategorySub !== ""
                    ? "?"
                    : "") +
                  (this.state.activePage > 0
                    ? "&page=" + this.state.activePage
                    : "") +
                  (this.state.keyword !== ""
                    ? "&keyword=" + this.state.keyword
                    : "") +
                  (this.state.status !== ""
                    ? "&status=" + this.state.status
                    : "") +
                  (this.state.selectedAvailability !== ""
                    ? "&availability=" + this.state.selectedAvailability
                    : "") +
                  (this.state.selectedProductCategory !== ""
                    ? "&category=" + this.state.selectedProductCategory
                    : "") +
                  (this.state.selectedProductCategorySub !== ""
                    ? "&subCategory=" + this.state.selectedProductCategorySub
                    : "")
                }
              >
                <Fa icon="edit" />
              </Link>{" "}
              <Link
                to="#"
                className="text-danger"
                onClick={this.handleDelete}
                data-item={item.id}
              >
                <Fa icon="trash-alt" />
              </Link>
            </td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={8}>
              <h5 className="text-danger">
                <em>No products found.</em>
              </h5>
            </td>
          </tr>
        );
      }
    }
  }

  renderCategories(categories) {
    if (categories instanceof Array && categories.length > 0) {
      if (categories.length === 1) {
        return categories.map((cat) => cat.name);
      } else {
        return categories.map((cat) => cat.name + ", ");
      }
    }
  }

  renderImage(item) {
    const { name, photos } = item;

    let primaryPhoto;
    if (photos[0] !== undefined || photos[0] != null) {
      primaryPhoto = photos[0];
    } else {
      primaryPhoto = {
        thumb: defaultProductImage,
      };
    }
    return (
      <Media className="mt-1">
        {primaryPhoto && primaryPhoto.thumb && (
          <Media left middle>
            <Media
              object
              data-src={primaryPhoto.thumb}
              src={primaryPhoto.thumb}
              alt={name}
              title={name}
            />
          </Media>
        )}
      </Media>
    );
  }

  renderPlaces() {
    if (this.state.places !== undefined || this.state.places != null) {
      return this.state.places.map((item, index) => (
        <option key={index} value={item._id.toString()}>
          {item.name}
        </option>
      ));
    }
  }

  toggleSwitchIsActive(product) {
    const userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    const productId = product._id;
    if (product) {
      this.props.updateProduct(
        product,
        productId,
        sessionToken,
        (error, result) => {
          if (result) {
            this.showNotification("Product successfully updated.");
          } else {
            if (error) {
              this.showNotificationError(error.response);
            } else {
              this.showNotificationError("Product not updated.");
            }
          }
        }
      );
    }
  }

  toggleSwitchIsLocked(product) {
    const userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    const productId = product._id;
    if (product) {
      this.props.updateProduct(
        product,
        productId,
        sessionToken,
        (error, result) => {
          if (result) {
            this.showNotification("Product successfully updated.");
          } else {
            if (error) {
              this.showNotificationError(error.response);
            }
          }
        }
      );
    }
  }

  toggleSwitchForSale(product) {
    const userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    const productId = product._id;
    if (product) {
      this.props.updateProduct(
        product,
        productId,
        sessionToken,
        (error, result) => {
          if (result) {
            this.showNotification("Product successfully updated.");
          } else {
            if (error) {
              this.showNotificationError(error.response);
            }
          }
        }
      );
    }
  }

  onDismiss = () => {
    this.setState({ hideTutorials: true });
    let pageInfo = JSON.parse(getSession("pageInfo"));
    pageInfo.hideTutorials = true;
    setSession("pageInfo", JSON.stringify(pageInfo));
  };

  onHideTutorials = () => {
    if (
      !window.confirm("Are you sure you want to hide this section permanently?")
    ) {
      return false;
    }
    let pageInfo = JSON.parse(getSession("pageInfo"));
    let userData = JSON.parse(getSession("userData"));
    const sessionToken = userData.sessionToken;
    const place = {
      hideTutorials: true,
    };
    api(sessionToken)
      .patch(`/provider/places/${pageInfo._id}`, place)
      .then((response) => {
        if (response && response.data && response.data.status === "ok") {
          this.setState({ hideTutorials: true });
          pageInfo.hideTutorials = true;
          setSession("pageInfo", JSON.stringify(pageInfo));
        } else {
          this.showNotificationError(
            "An unknown error occured. Please try again!"
          );
        }
      })
      .catch((error) => {
        this.setState({ submitted: false, isSaving: false });
        if (error.response && typeof error.response === "string") {
          this.showNotificationError(error.response);
        } else {
          this.showNotificationError(
            "An unknown error occured. Please try again!"
          );
        }
      });
  };

  showNotification(message) {
    if (message && typeof message === "string") {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "success",
        icon: "",
        autoDismiss: 2,
      };
      if (
        notification &&
        this.refs.notify &&
        this.refs.notify.notificationAlert
      ) {
        this.refs.notify.notificationAlert(notification);
      }
    }
  }

  showNotificationError(message) {
    if (message && typeof message === "string") {
      const notification = {
        place: "tc",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: "danger",
        icon: "",
        autoDismiss: 2,
      };
      if (
        notification &&
        this.refs.notify &&
        this.refs.notify.notificationAlert
      ) {
        this.refs.notify.notificationAlert(notification);
      }
    }
  }

  handleChangeKeyword = (e) => {
    let { value } = e.target;
    this.setState({ keyword: value });
  };

  handleEnter = (e) => {
    let { key } = e;
    if (key === "Enter") {
      this.setState({ isLoading: true, activePage: 1 });
      let { keyword } = this.state;

      let defaultPage = JSON.parse(getSession("defaultPage"));
      let url = this.props.location.search;
      let query = queryString.parse(url);
      delete query.message;
      delete query.page;
      if (keyword !== "") {
        query.keyword = keyword.trim();
      } else {
        delete query.keyword;
      }
      let queryStr = "?" + queryString.stringify(query);
      this.refreshRestoredProductList(queryStr, defaultPage);
    }
  };

  handleDelete = (e) => {
    if (!window.confirm("Do you want to delete this product?")) {
      return false;
    }
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    const message = "Restored Product has been deleted successfully.";
    let queryStr = "?" + queryString.stringify(query);
    const productId = e.currentTarget.dataset.item;
    console.log(productId);
    if (productId) {
      const userData = JSON.parse(getSession("userData"));
      if (userData) {
        const sessionToken = userData.sessionToken;
        this.props.deleteRestored(productId, sessionToken, (error, result) => {
          if (!error && result) {
            if (result.status === 204) {
              this.showNotification(message);
              this.refreshRestoredProductList(queryStr, defaultPage);
            }
          } else {
            if (error) {
              if (
                error.response &&
                error.response.status &&
                error.response.status === 403
              ) {
                setTimeout(() => {
                  this.setState({ isLoading: true });
                  let defaultPage = JSON.parse(getSession("defaultPage"));
                  let url = this.props.location.search;
                  let query = queryString.parse(url);
                  delete query.message;
                  delete query.page;
                  let queryStr = "?" + queryString.stringify(query);
                  this.setState({ activePage: 1 });
                  this.refreshRestoredProductList(queryStr, defaultPage);
                }, 1000);
                this.showNotificationError(
                  "You are not allowed to delete this product."
                );
              } else {
                this.showNotificationError(error.response);
              }
            }
          }
        });
      } else {
        this.props.history.push("/login");
        window.location.reload();
      }
    }
  };

  handleChangeAvailability = (e) => {
    this.setState({ isLoading: true });
    let { value } = e.target;

    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    delete query.page;
    if (value !== "") {
      query.availability = value;
    } else {
      delete query.availability;
    }
    let queryStr = "?" + queryString.stringify(query);

    this.setState({ selectedAvailability: value, activePage: 1 });
    this.refreshRestoredProductList(queryStr, defaultPage);
  };

  handleChangeProductCategory = (e) => {
    this.setState({ isLoading: true });
    let value = "";
    const productCategoriesAll = this.state.productCategoriesAll;
    let productCategoriesSub = [];
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    delete query.page;
    delete query.subCategory;
    if (e && e.value) {
      value = e.value;
      productCategoriesSub = productCategoriesAll.filter(
        (item) => item.parent && item.parent.id.toString() === value
      );
      query.category = value;
    } else {
      delete query.category;
      this.setState({ selectedProductCategorySub: "" });
    }
    let queryStr = "?" + queryString.stringify(query);
    this.setState({
      selectedProductCategory: value,
      productCategoriesSub: productCategoriesSub,
      activePage: 1,
    });
    this.refreshRestoredProductList(queryStr, defaultPage);
  };

  handleChangeProductCategorySub = (e) => {
    this.setState({ isLoading: true });
    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    delete query.page;
    let value = "";
    if (e && e.value) {
      value = e.value;
      query.subCategory = value;
    } else {
      delete query.subCategory;
    }
    let queryStr = "?" + queryString.stringify(query);

    this.setState({ selectedProductCategorySub: value, activePage: 1 });
    this.refreshRestoredProductList(queryStr, defaultPage);
  };

  handleChangeProductStatus = (e) => {
    this.setState({ isLoading: true });
    let { value } = e.target;

    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    delete query.page;
    if (value !== "") {
      query.status = value;
    } else {
      delete query.status;
    }
    let queryStr = "?" + queryString.stringify(query);

    this.setState({ status: value, activePage: 1 });
    this.refreshRestoredProductList(queryStr, defaultPage);
  };

  handlePageChange = (pageNumber) => {
    this.setState({ isLoading: true });
    let defaultPage = JSON.parse(getSession("defaultPage"));

    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    query.page = pageNumber;
    let queryStr = "?" + queryString.stringify(query);

    this.setState({ activePage: pageNumber });
    this.refreshRestoredProductList(queryStr, defaultPage);
  };

  handleChangePerPage = (e) => {
    this.setState({ isLoading: true });
    let { value } = e.target;

    let defaultPage = JSON.parse(getSession("defaultPage"));
    let url = this.props.location.search;
    let query = queryString.parse(url);
    delete query.message;
    delete query.page;
    if (value !== "") {
      query.size = value;
    } else {
      delete query.size;
    }
    let queryStr = "?" + queryString.stringify(query);
    this.setState({ size: value, activePage: 1 });
    this.refreshRestoredProductList(queryStr, defaultPage);
  };

  handlePublishChecked = () => {
    const checked = this.state.checked;
    let items = 0;
    let updatedItems = 0;
    let hasError = false;
    if (checked instanceof Array && checked.length > 0) {
      if (
        !window.confirm("Are you sure you want to publish this product(s)?")
      ) {
        return false;
      }
      this.setState({ isProcessing: true });
      checked.forEach((productId, index, array) => {
        const userData = JSON.parse(getSession("userData"));
        const sessionToken = userData.sessionToken;
        let product = this.state.products.find(
          (item) => item._id === productId
        );
        product.isActive = true;
        this.props.updateProduct(
          product,
          productId,
          sessionToken,
          (error, result) => {
            if (result) {
              this.setState((prevState) => {
                const list = prevState.products.map((item) =>
                  checkedItems(item, prevState.products)
                );
                return {
                  list,
                };
              });
              updatedItems++;
            } else {
              if (error) {
                hasError = true;
              }
            }
            items++;
            if (items === array.length) {
              this.setState({ isProcessing: false });
              if (updatedItems > 0) {
                this.showNotification("Products has been published!");
              }
              if (hasError) {
                this.showNotificationError(
                  "There is a error in updating the product(s)!"
                );
              }
            }
          }
        );
      });
    } else {
      this.showNotificationError("Please select a product!");
      return false;
    }
  };

  handleUnpublishChecked = () => {
    const checked = this.state.checked;
    let items = 0;
    let updatedItems = 0;
    let hasError = false;
    if (checked instanceof Array && checked.length > 0) {
      if (
        !window.confirm("Are you sure you want to unpublish this product(s)?")
      ) {
        return false;
      }
      this.setState({ isProcessing: true });
      checked.forEach((productId, index, array) => {
        const userData = JSON.parse(getSession("userData"));
        const sessionToken = userData.sessionToken;
        let product = this.state.products.find(
          (item) => item._id === productId
        );
        product.isActive = false;
        this.props.updateProduct(
          product,
          productId,
          sessionToken,
          (error, result) => {
            if (result) {
              this.setState((prevState) => {
                const list = prevState.products.map((item) =>
                  checkedItems(item, prevState.products)
                );
                return {
                  list,
                };
              });
              updatedItems++;
            } else {
              if (error) {
                hasError = true;
              }
            }
            items++;
            if (items === array.length) {
              this.setState({ isProcessing: false });
              if (updatedItems > 0) {
                this.showNotification("Products has been unpublished!");
              }
              if (hasError) {
                this.showNotificationError(
                  "There is a error in updating the product(s)!"
                );
              }
            }
          }
        );
      });
    } else {
      this.showNotificationError("Please select a product!");
      return false;
    }
  };

  copyToClipboard = (e) => {
    const productId = e.currentTarget.dataset.id;
    navigator.clipboard
      .writeText(productId)
      .then(() => {
        this.showNotification("Product ID copied to clipboard!");
      })
      .catch((err) => {
        this.showNotificationError("Error in copying Product ID!");
      });
  };

  refreshRestoredProductList(queryStr, pageId) {
    const query = queryString.parse(queryStr);
    const message = query.message;

    if (message) {
      delete query.message;
      this.showNotification(message);
    }

    this.props.history.push("/product-restored" + queryStr);
    const userData = JSON.parse(getSession("userData"));
    const pageInfo = JSON.parse(getSession("pageInfo"));
    const { sessionToken } = userData;
    if (userData !== null && pageInfo) {
      this.props.getRestoredList(pageId, query, sessionToken, (err, res) => {
        if (!err && res) {
          this.setState({
            restoredList: res.docs,
            pagination: {
              limit: res.pageSize,
              total: res.totalRows,
            },
          });
        }
        this.setState({ isLoading: false });
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  getCategories(pageInfo, defaultPage, sessionToken) {
    if (pageInfo && pageInfo.useCreatedProductCategory) {
      let pageId = defaultPage;
      const GAISANO_CDO_MAIN = "5ccfe6aeb99ae3280fd246dc";
      const GAISANO_BRANCHES = [
        "5e6c8ee3a5ac68a35e071e07", // Malaybalay
        "5e6c8ed7a5ac68a35e071e05", // Gensan
        "5e6c8edda5ac68a35e071e06", // Iligan
        "5e6c8ecea5ac68a35e071e04", // Tagum
        "5e9e50973c47b363587eb3d9", // Davao
      ];
      const gaisanoBranch = GAISANO_BRANCHES.find(
        (item) => item === defaultPage
      );
      if (gaisanoBranch) {
        pageId = GAISANO_CDO_MAIN;
      }
      this.props.getProductCategoriesAllV2(
        {},
        pageId,
        sessionToken,
        (error, result) => {
          if (!error && result) {
            const categoriesAll = result;
            if (categoriesAll instanceof Array && categoriesAll.length > 0) {
              let productCategoriesAll = [];
              let productCategoriesMain = [];
              let productCategoriesSub = [];
              categoriesAll.forEach((item) => {
                const category = {
                  value: item._id,
                  label:
                    item.isActive === true
                      ? item.name
                      : item.name + " - INACTIVE",
                  parent: item.parent,
                };
                productCategoriesAll.push(category);
              });
              productCategoriesMain = productCategoriesAll.filter(
                (item) => !item.parent
              );
              const categoryFirst = [
                {
                  value: "",
                  label: "All",
                  parent: "",
                },
                {
                  value: "no-category",
                  label: "No category",
                  parent: "",
                },
              ];
              productCategoriesMain = [
                ...categoryFirst,
                ...productCategoriesMain,
              ];
              if (this.state.selectedProductCategory) {
                productCategoriesSub = productCategoriesAll.filter(
                  (item) =>
                    item.parent &&
                    item.parent.id.toString() ===
                      this.state.selectedProductCategory
                );
              }
              this.setState({
                productCategoriesAll: productCategoriesAll,
                productCategories: productCategoriesMain,
                productCategoriesSub: productCategoriesSub,
              });
            } else {
              this.setState({
                productCategories: [],
              });
            }
          }
        }
      );
    } else {
      this.props.getProductCategoriesAll({}, sessionToken, (error, result) => {
        if (!error && result) {
          const categoriesAll = result;
          if (categoriesAll instanceof Array && categoriesAll.length > 0) {
            let productCategoriesAll = [];
            let productCategoriesMain = [];
            let productCategoriesSub = [];
            categoriesAll.forEach((item) => {
              const category = {
                value: item._id,
                label:
                  item.isActive === true
                    ? item.name
                    : item.name + " - INACTIVE",
                parent: item.parent,
              };
              productCategoriesAll.push(category);
            });
            productCategoriesMain = productCategoriesAll.filter(
              (item) => !item.parent
            );
            const categoryFirst = [
              {
                value: "",
                label: "All",
                parent: "",
              },
              {
                value: "no-category",
                label: "No category",
                parent: "",
              },
            ];
            productCategoriesMain = [
              ...categoryFirst,
              ...productCategoriesMain,
            ];
            if (this.state.selectedProductCategory) {
              productCategoriesSub = productCategoriesAll.filter(
                (item) =>
                  item.parent &&
                  item.parent.id.toString() ===
                    this.state.selectedProductCategory
              );
            }
            this.setState({
              productCategoriesAll: productCategoriesAll,
              productCategories: productCategoriesMain,
              productCategoriesSub: productCategoriesSub,
            });
          } else {
            this.setState({
              productCategories: [],
            });
          }
        }
      });
    }
  }

  renderNoPageAdded() {
    return (
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref="notify" />
        </div>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Alert color="danger">
                  <h4 className="alert-heading">No Page Added</h4>
                  <hr />
                  <p className="mb-0">
                    You have not added a page yet. Click{" "}
                    <Link to="/add-page">here</Link> to add a new page.
                  </p>
                </Alert>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  renderPageNotVerified() {
    return (
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref="notify" />
        </div>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Alert color="danger">
                  <h4 className="alert-heading">
                    You Have Not Agreed with our Terms & Policies
                  </h4>
                  <hr />
                  <p className="mb-0">
                    You must agree with our Terms & Policies. Click{" "}
                    <Link to="/statement-of-agreement">here</Link> to read our
                    Terms & Policies.
                  </p>
                </Alert>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    let pageInfo = JSON.parse(getSession("pageInfo"));
    let hasCategories = JSON.parse(getSession("hasCategories"));
    let hasProducts = JSON.parse(getSession("hasProducts"));
    if (pageInfo) {
      if (pageInfo.pageType === "5dea2304f6bba08323a3ddce") {
        this.props.history.push("/tours");
        window.location.reload();
      }
    }
    if (pageInfo && pageInfo._id) {
      if (pageInfo.isVerified === true) {
        return (
          <>
            <div className="content">
              <div className="react-notification-alert-container">
                <NotificationAlert ref="notify" />
              </div>
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <h4 className="title">
                        Restored Product- <em>{pageInfo.name}</em>
                      </h4>
                      <Row>
                        {!hasCategories && (
                          <Col sm="12">
                            <Row>
                              <Col md="12">
                                <Alert color="warning">
                                  <p className="mb-0">
                                    You have not added a category yet. Click{" "}
                                    <Link to="/product-categories/new">
                                      here
                                    </Link>{" "}
                                    to add a product category.
                                  </p>
                                </Alert>
                              </Col>
                            </Row>
                          </Col>
                        )}
                        {hasCategories &&
                          hasProducts &&
                          pageInfo.isActive !== true && (
                            <Col sm="12">
                              <Row>
                                <Col md="12">
                                  <Alert color="danger">
                                    <h4 className="alert-heading">
                                      Store Not Activated
                                    </h4>
                                    <hr />
                                    <p className="mb-0">
                                      You must activate your store so it will
                                      appear in the app and you can start
                                      selling.
                                      <br />
                                      Click{" "}
                                      <Link to="/activate-store">here</Link> to
                                      activate you store.
                                    </p>
                                  </Alert>
                                </Col>
                              </Row>
                            </Col>
                          )}

                        <Col md="6">
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <label
                                  htmlFor="keyword"
                                  className="control-label"
                                >
                                  Search:
                                </label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Input
                                  id="keyword"
                                  name="keyword"
                                  type="text"
                                  placeholder="Search product..."
                                  onChange={this.handleChangeKeyword}
                                  onKeyPress={this.handleEnter}
                                  value={this.state.keyword}
                                ></Input>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="pr-md-1" md="3">
                              <FormGroup>
                                <label
                                  htmlFor="startDate"
                                  className="control-label"
                                  style={{ display: "block" }}
                                >
                                  Start Date:
                                </label>

                                <DatePicker
                                  name="startDate"
                                  className="form-control"
                                  selectsStart
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  selected={this.state.startDate}
                                  onChange={this.handleChangeStartDate}
                                  dateFormat="MMMM d, yyyy"
                                  autoComplete="off"
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-1" md="3">
                              <FormGroup>
                                <label
                                  htmlFor="endDate"
                                  className="control-label"
                                  style={{ display: "block" }}
                                >
                                  End Date:
                                </label>

                                <DatePicker
                                  name="endDate"
                                  selectsEnd
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  className="form-control"
                                  selected={this.state.endDate}
                                  onChange={this.handleChangeEndDate}
                                  dateFormat="MMMM d, yyyy"
                                  autoComplete="off"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6">
                          <Row>
                            <Col className="pull-right" md="12">
                              <FormGroup style={{ textAlign: "right" }}>
                                <p className="control-label">Filter:</p>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Select
                                  className="react-select"
                                  styles={{ fontSize: "13px !important" }}
                                  options={this.state.productCategories}
                                  onChange={this.handleChangeProductCategory}
                                  placeholder="Select main category"
                                  value={this.state.productCategories.filter(
                                    (item) =>
                                      item.value ===
                                      this.state.selectedProductCategory
                                  )}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Select
                                  className="react-select"
                                  styles={{ fontSize: "13px !important" }}
                                  isClearable={true}
                                  options={this.state.productCategoriesSub}
                                  onChange={this.handleChangeProductCategorySub}
                                  placeholder="Select sub category"
                                  value={this.state.productCategoriesSub.filter(
                                    (item) =>
                                      item.value ===
                                      this.state.selectedProductCategorySub
                                  )}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Input
                                  id="availability"
                                  name="availability"
                                  type="select"
                                  onChange={this.handleChangeAvailability}
                                  value={this.state.selectedAvailability}
                                >
                                  <option value="">
                                    For Sale/Availability
                                  </option>
                                  <option value="available">Available</option>
                                  <option value="notAvailable">
                                    Not Available
                                  </option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Input
                                  id="productStatus"
                                  name="productStatus"
                                  type="select"
                                  onChange={this.handleChangeProductStatus}
                                  value={this.state.status}
                                >
                                  <option value="">Status</option>
                                  <option value="active">Active</option>
                                  <option value="inActive">Inactive</option>
                                </Input>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ textAlign: "right" }} md="12">
                          <Link
                            to="#"
                            onClick={this.exportfileList.bind(this)}
                            className="btn btn-round btn-info btn-sm"
                          >
                            <Fa icon="print" /> &nbsp;Print Report
                          </Link>
                          <Link
                            to="/product-restored/new"
                            alt="Add New Restored Product"
                            title="Add New Restored Product"
                            className="btn btn-sm btn-round btn-info"
                          >
                            <Fa icon="plus" />
                            &nbsp;Add New
                          </Link>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Table className="tablesorter table-striped" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th style={{ whiteSpace: "nowrap" }}>
                              Date Created{" "}
                            </th>
                            <th style={{ whiteSpace: "nowrap" }}>
                              Restored Product Name{" "}
                            </th>
                            <th style={{ whiteSpace: "nowrap" }}>Unit</th>
                            <th style={{ whiteSpace: "nowrap" }}>Category</th>
                            <th style={{ whiteSpace: "nowrap" }}>Price</th>
                            <th style={{ whiteSpace: "nowrap" }}>
                              Effective Date
                            </th>
                            <th style={{ whiteSpace: "nowrap" }}>Status</th>
                            <th style={{ whiteSpace: "nowrap" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.renderRows(this.state.restoredList)}
                        </tbody>
                      </Table>
                    </CardBody>
                    <CardFooter>
                      <Row>
                        <Col md="12">
                          <Row>
                            <Col md="12" lg="12">
                              {this.state.pagination &&
                                this.state.pagination.total > 0 && (
                                  <>
                                    <Pagination
                                      innerClass="pagination"
                                      activePage={this.state.activePage}
                                      itemsCountPerPage={
                                        this.state.pagination.limit
                                      }
                                      totalItemsCount={
                                        this.state.pagination.total
                                      }
                                      pageRangeDisplayed={5}
                                      onChange={this.handlePageChange}
                                    />
                                    <p>
                                      Page <em>{this.state.activePage}</em> of{" "}
                                      <em>
                                        {Math.ceil(
                                          this.state.pagination.total /
                                            this.state.pagination.limit
                                        )}
                                      </em>{" "}
                                      of{" "}
                                      <em>
                                        {numberWithCommasOnly(
                                          this.state.pagination.total
                                        )}
                                      </em>{" "}
                                      products.
                                    </p>
                                    <Input
                                      style={{
                                        marginBottom: "5px",
                                        width: "auto",
                                      }}
                                      id="perPage"
                                      name="perPage"
                                      type="select"
                                      onChange={this.handleChangePerPage}
                                      value={this.state.size}
                                    >
                                      <option value="10">10 / page</option>
                                      <option value="25">25 / page</option>
                                      <option value="50">50 / page</option>
                                      <option value="100">100 / page</option>
                                    </Input>
                                  </>
                                )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </div>
            <LoadingOverlay
              active={this.state.isProcessing}
              spinner
              text="Processing..."
            ></LoadingOverlay>
            <LoadingOverlay
              active={this.state.isGeneratingXls}
              spinner
              text="Processing..."
            ></LoadingOverlay>
          </>
        );
      } else {
        return this.renderPageNotVerified();
      }
    } else {
      return this.renderNoPageAdded();
    }
  }
}

const numberWithCommas = (x) => {
  return priceRound(x).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const numberWithCommasOnly = (x) => {
  return priceRound(x, 0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const checkedItems = (item, product) => {
  if (item._id === product._id) {
    return (item = product);
  }
};

const priceRound = (price, dec) => {
  if (dec === undefined) {
    dec = 2;
  }
  if (price !== 0) {
    if (!price || isNaN(price)) {
      throw new Error("price is not a number" + price);
    }
  }
  const str = parseFloat(Math.round(price * 100) / 100).toFixed(dec);
  return str;
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  getProductsByPageId,
  getProductsGroceryByPageId,
  getProductsByProviderId,
  getProviderPlaces,
  getProductCategoriesAll,
  getProductCategoriesAllV2,
  updateProduct,
  deleteRestored,
  getRestoredList,
  exportRestoredList,
})(RestoredProducts);
